<template>
    <div>
        <div v-if="dialog != false" style="z-index:100" class="position-fixed vw-100">
            <transition name="modal">
                <div style="z-index:10000;" class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div v-if="dialog.errors != undefined">
                                <h3 class="text-danger">Attenzione!</h3>
                                <div v-for="(error,index) in dialog.errors" :key=index>
                                    <p class="text-danger">{{error}}</p>
                                </div>
                            </div>
                            <div v-if="dialog.infos != undefined">
                                <div v-for="(info,index) in dialog.infos" :key=index>
                                    <p>{{info}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="dialog = false">Close</button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="container border rounded bg-white">
            <div class="bg-info rounded-top row text-center">
                <h2 class="m-0 p-2 text-light w-100"><strong>Area Riservata</strong></h2>
            </div>
            <div class="row d-flex justify-content-center">
                <img style="height:300px; width:300px;" class="p-5" src="../../assets/img/logo-cropped-light.png">
            </div>
            <div class="row">
                <form v-if="!recovery" class="w-100" @submit.prevent="loginProcess()">
                    <div class="col-12 pl-5 pr-5">
                        <strong>Username</strong>
                    </div>
                    <div class="col-12 pl-5 pr-5">
                        <input class="w-100" v-model="username" type="text" placeholder="username">
                    </div>
                    <div class="col-12 pl-5 pr-5 mt-2">
                        <strong>Password</strong>
                    </div>
                    <div class="col-12 pl-5 pr-5">
                        <input class="w-100" v-model="password" type="password" placeholder="password">
                    </div>
                    <div v-if="key">
                        <div class="col-12 pl-5 pr-5 mt-2">
                            <strong>Codice di accesso</strong>
                        </div>
                        <div class="col-12 pl-5 pr-5">
                            <input class="w-100" v-model="access_key" type="text"><br>
                        </div>
                    </div>
                    <div class="col-12 pl-5 mt-3 pr-5">
                        <input class="btn btn-info w-100" type="submit" value="Login">
                    </div>
                </form>
            </div>
            <div class="row">
                <div class="col-12 pl-5 mt-3 mb-4 pr-5">
                    <button class="w-100 btn btn-info" @click="recovery = !recovery">Recupera Password</button>
                </div>
                <form class="w-100" v-if="recovery" @submit.prevent="recoveryProcess()">
                    <div class="col-12 pl-5 pr-5">
                        <strong>Username</strong>
                    </div>
                    <div class="col-12 pl-5 pr-5">
                        <input class="w-100" v-model="username" type="text">
                    </div>
                    <div class="col-12 pl-5 pr-5 mt-2">
                        <strong>E-mail</strong>
                    </div>
                    <div class="col-12 pl-5 pr-5">
                        <input class="w-100" v-model="e_mail" type="text">
                    </div>
                    <div class="col-12 pl-5 mt-3 pr-5">
                        <input class="btn btn-info w-100 mb-4" type="submit" value="Conferma">
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginForm',
    data: function () {
        return {
            e_mail: '',
            username: '',
            password: '',
            access_key: '',
            csrf_token: '',
            key: false,
            /*UI*/
            dialog: false,
            recovery: false,
        }
    },
    mounted(){
        this.getToken();
    },
    methods: {
        getToken: async function (){
            const res = await fetch(`${this.$api_url}/backend/token_gen.php`,{
                method: "POST",
                credentials: 'include'
            });
            if(res.status != 200){
                this.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                this.csrf_token = json.csrf_token;
            }
        },
        loginProcess: async function () {
            var formData = new FormData();
            formData.append('csrf_token',this.csrf_token);
            formData.append('username',this.username);
            formData.append('password',this.password);
            this.access_key != '' ? formData.append('access_key',this.access_key) : 0;
            const res = await fetch(`${this.$api_url}/backend/login.php`, {
                method: "POST",
                body: formData,
                credentials: 'include'
            });
            if (res.status != 200) {
                this.setDialog('errors',['unexpected_error']);                
            } else {
                const json = await res.json();
                json.access_token != undefined ? this.resetCookies(json.access_token) : 0;
                json.redirect != undefined ? this.$router.replace(json.redirect) : 0;
                json.errors != undefined ? this.setDialog('errors',json[0]) : 0;
                json.key != undefined ? this.key = true : 0;
            }
        },
        recoveryProcess: async function(){
            var formData = new FormData();
            formData.append('csrf_token',this.csrf_token)
            formData.append('username',this.username);
            formData.append('e_mail',this.e_mail);
            const res = await fetch(`${this.$api_url}/backend/recovery.php`,{
                method: "POST",
                body: formData,
                credentials: 'include'
            });
            if(res.status != 200){
                this.setDialog('errors',['unexpected_error']);
            }else{
                const json = await res.json();
                json.redirect != undefined ? this.$router.replace(json.redirect) : 0;
                json.errors != undefined ? this.setDialog('errors',json[0]) : 0;
            }
        },
        resetCookies: function (token) {
            this.$cookies.remove('access_token');
            this.$cookies.set('access_token', token);
        },
        setDialog(value,data){
            this.dialog = {}
            this.dialog[value] = this.$root.responseTranslator(value,data);
        },
    }
}
</script>