<template>
    <div>
        <div class="bg-light vh-100 vw-100 position-fixed d-flex justify-content-center flex-column">
            <LoginForm style="overflow-y:auto; overflow-x:hidden;"/>
        </div>
    </div>
</template>

<script>
import LoginForm from '../../components/backoffice/LoginForm.vue'

export default {
    name: 'Login',
    components: {
        LoginForm
    }
}
</script>